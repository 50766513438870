* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background: rgb(233, 233, 233);
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  height: auto;
}

.calculator {
  background: rgb(211, 211, 211);
  box-shadow: 0 3px 8px 5px #c9c9c9;
}

.big-bar {
  width: 100%;
  height: 50px;
  padding-right: 5px;
  font-size: 20px;
  background: gray;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.calc {
  display: flex;
  gap: 2px;
}

.nums {
  display: flex;
  width: 210px;
  gap: 2px;
}

.nums ul {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 50%;
}

.zero {
  width: 203%;
}

.nums ul li {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
}

.nums ul .list:active {
  opacity: 0.5;
}

.ops {
  height: 100%;
  width: 70px;
}

.ops ul {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ops ul li {
  background: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.ops ul li:active {
  opacity: 0.5;
}

button {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
}
